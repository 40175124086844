@import "./global.scss";

:export {
  mobileBreakpoint: stripUnit($mobile-breakpoint);
  tabletBreakpoint: stripUnit($tablet-breakpoint);
  maxContentWidth: stripUnit($max-content-width);
  maxWidth: stripUnit($max-width);
  mainColor: $mainColor;
  separator: $separator;
  gray-100: $gray-100;
}
