.advantagesList {
  list-style: none;
}

.advantagesList--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.advantagesList__item {
  font-weight: 400;
  color: #1e1e1e;
  line-height: 130%;
  padding-bottom: 0;
  display: flex;
  align-items: baseline;
  margin-top: 0.972rem;
}

.advantagesList__item:first-child {
  margin-top: 0;
}

.advantagesList--row .advantagesList__item::before,
.advantagesList--default .advantagesList__item::before {
  content: url("data:image/svg+xml,%3Csvg width='18' height='13.5' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.6426 2.20051C18.1339 2.71885 18.1165 3.54174 17.6039 4.03849L5.98137 15.3L0.396123 9.88824C-0.116546 9.39149 -0.133863 8.5686 0.357445 8.05025C0.848754 7.53191 1.66264 7.5144 2.17531 8.01115L5.98137 11.699L15.8247 2.1614C16.3374 1.66466 17.1512 1.68216 17.6426 2.20051Z' fill='%234D92DC'/%3E%3C/svg%3E%0A");
  margin-right: 0.5556rem;
}

.advantagesList__item__section {
}

.advantagesList__item__section_icon {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.6944rem 0 0;
}

.advantagesList__item__section_hint {
  margin: 0 0 0 0.6944rem;
  font-weight: initial;
}

.advantagesList--solutionBanner .advantagesList__item__section_icon {
  width: 2.1528rem;
  height: 2.1528rem;
  margin: 0 1.3889rem 0 0;
}

.advantagesList--solutionBanner .advantagesList__item {
  font-weight: 400;
  font-size: 1.1111rem;
  line-height: 0.7639rem;
  display: flex;
  align-items: center;
  padding: 1.3889rem 0;
  border-bottom: 1px solid #edeff1;
  margin: 0;
  max-height: unset;
}

.advantagesList--solutionBanner .advantagesList__item:last-child {
  border-bottom: unset;
  padding: 1.3889rem 0 0;
}
.advantagesList--solutionBanner .advantagesList__item:first-child {
  padding: 0 0 1.3889rem;
}

@media screen and (max-width: 450px) {
  .advantagesList {
    display: flex;
    flex-wrap: wrap;
  }
  .advantagesList__item {
    width: 100%;
  }

  .advantagesList--default .advantagesList__item {
    display: flex;
  }

  .advantagesList--row .advantagesList__item {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.6944rem;
    margin-right: 0;
  }
  .advantagesList--solutionBanner .advantagesList__item {
    padding: 0.9722rem 0;
  }
  .advantagesList--solutionBanner .advantagesList__item:first-child {
    padding: 0 0 0.9722rem;
  }
  .advantagesList--solutionBanner .advantagesList__item:last-child {
    padding: 0.9722rem 0 0;
  }
}
