.destra-widget-form {
  all: initial;
  font-family: "Stem", sans-serif !important;
}
:root {
  --s1: 0.0694rem;
  --s2: 0.1389rem;
  --s3: 0.2083rem;
  --s4: 0.2778rem;
  --s5: 0.3472rem;
  --s6: 0.4167rem;
  --s7: 0.4861rem;
  --s8: 0.5556rem;
  --s9: 0.6250rem;
  --s10: 0.6944rem;
  --s11: 0.7639rem;
  --s12: 0.8333rem;
  --s13: 0.9028rem;
  --s14: 0.9722rem;
  --s15: 1.0417rem;
  --s16: 1.1111rem;
  --s17: 1.1806rem;
  --s18: 1.2500rem;
  --s19: 1.3194rem;
  --s20: 1.3889rem;
  --s21: 1.4583rem;
  --s22: 1.5278rem;
  --s23: 1.5972rem;
  --s24: 1.6667rem;
  --s25: 1.7361rem;
  --s26: 1.8056rem;
  --s27: 1.8750rem;
  --s28: 1.9444rem;
  --s29: 2.0139rem;
  --s30: 2.0833rem;
  --s31: 2.1528rem;
  --s32: 2.2222rem;

  --s34: 2.3611rem;
  --s35: 2.4306rem;
  --s36: 2.5000rem;
  
  --s39: 2.7083rem;
  --s40: 2.7778rem;
  --s41: 2.8472rem;
  --s42: 2.9167rem;

  --s45: 3.1250rem;
  --s46: 3.1944rem;
  
  --s48: 3.3333rem;
  --s49: 3.4028rem;
  --s50: 3.4722rem;

  --s52: 3.6111rem;  
  
  --s54: 3.7500rem;

  --s60: 4.1667rem;

  --s62: 4.3056rem;

  --s64: 4.4444rem;
  --s65: 4.5139rem;

  --s67: 4.6528rem;

  --s70: 4.8611rem;

  --s75: 5.2083rem;
  --s76: 5.2778rem;
  
  --s79: 5.4861rem;
  --s80: 5.5556rem;
  
  --s88: 6.1111rem;

  --s90: 6.2500rem;
  
  --s92: 6.3889rem;
  
  --s100: 6.9444rem;

  --mainColor: #4D92DC;
}