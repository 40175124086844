.auth_phone_timer {
  color: #777777;
  margin: 1.3889rem 0 1.3889rem 0;
  font-family: 'Stem', sans-serif;
}

.auth_phone_timer__newcode {
  margin: 1.3889rem 0 1.3889rem 0;
  color: #4D92DC;
  cursor: pointer;
  font-family: 'Stem', sans-serif;
}