@use "sass:math";

$mobile-breakpoint: 450px;
$tablet-breakpoint: 1024px;
$mainColor: #4d92dc;
$separator: #e5e5e5;
$gray-100: #777;
$max-content-width: 1350px;
$max-width: 1440px;

@function stripUnit($value) {
  @if type-of($value) == "number" and not unitless($value) {
    @return math.div($value, ($value * 0 + 1));
  }
  @return $value;
}

@function rem($pxValue) {
  @return #{calc(stripUnit($pxValue) / 14.4)}rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 1.2s;
}

@keyframes smoothFadeLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0px, 0px);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// @keyframes smoothFadeTop {
//   from {
//     opacity: 0;
//     transform: translate3d(0px, -50px, 0px);
//   }
//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }

// @keyframes smoothFadeBottom {
//   from {
//     opacity: 0;
//     transform: translate3d(0px, 50px, 0px);
//   }
//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }
