.component {
  display: inline-block;
  vertical-align: middle;
  min-width: 1.5rem;
  max-width: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  border: 0.0625rem solid var(--mainColor);
  position: relative;
  cursor: pointer;
}

.checked::before {
  display: block;
  left: -0.1rem;
  content: "✔";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 1.5rem;
  max-width: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
  line-height: 1.2rem;
  background-color: var(--mainColor);
  border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  text-align: center;
  font-size: 0.625rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden {
  display: none;
}

.checkbox__error {
  border: 0.0625rem solid #fa5252;
}
