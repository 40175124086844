@import "../../../styles/exportVariables.scss";

.formWrapper {
  background: rgba(77, 146, 220, 0.1);
  border-radius: 20px;
  padding: rem(20px);
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: rem(40px);
  grid-template-columns: 1fr min-content;
  @media screen and (max-width: 450px) {
    grid-gap: rem(20px);
    grid-template-columns: unset;
    grid-template-rows: 1fr min-content;
  }
}
.form {
  background: #ffffff;
  width: 100%;
  padding: var(--s40);
  border-radius: var(--s40);
  max-width: 29.167rem;
  box-sizing: border-box;
}
.form__section {
  width: 100%;
}
.form__field:not(:first-child) {
  margin-top: var(--s20);
}
.form__dates {
  display: flex;
}
.time_call {
  width: 100%;
}
.date_call {
  width: 100%;
  margin-right: var(--s20);
}
.form__whatsup {
  display: flex;
  margin-top: var(--s40);
  align-items: center;
}
.form_whatsup_text {
  padding-left: var(--s14);
}
.form__btn {
  margin-top: var(--s40);
}
.form__terms__default {
  display: flex;
  margin-top: var(--s40);
}
.form__terms__modalDocument {
  display: flex;
  margin-top: var(--s60);
}
.form__terms__description {
  margin-left: var(--s14);
  color: #adb5bd;
  font-family: 'Stem', sans-serif;
}

.form__terms__description.checkboxError {
  color: #FA5252;
}

.form__terms__description__link {
  color: #4d92dc;
}

.authPhone__newView {
  width: 100%;
}

.authPhone__newView__phone__input,
.authPhone__newView__phone__button__default,
.authPhone__newView__actions__input {
  width: 27.7778rem;
}

.authPhone__newView__actions__description {
  display: flex;
}

.authPhone__newView__actions__description-number {
  font-weight: 500;
  margin: 0 0.4167rem 0 0.4167rem;
}

.authPhone__newView__phone__input,
.authPhone__newView__actions__input,
.authPhone__newView__phone__button__default {
  margin: 2.083rem 0 0 0;
}
.authPhone__newView__phone__button__default .button__section-text {
  font-size: 1.25rem;
  line-height: 1.613rem;
}

.authPhone__newView__actions__description-button,
.authPhone__newView__actions__sms {
  color: #4d92dc;
  cursor: pointer;
}

/* template main */

.authPhone__newView__main .authPhone__newView__phone__input,
.authPhone__newView__main .authPhone__newView__phone__button__default,
.authPhone__newView__main .authPhone__newView__actions__input {
  width: 100%;
  position: relative;
}

.authPhone__newView__main .authPhone__newView__phone__button__default {
  margin: 2.7778rem 0 0 0;
}
.authPhone__newView__main .authPhone__newView__phone__button__modalDocument {
  margin: 2.2916rem 0 0 0;
}
.authPhone__newView__main .authPhone__newView__actions__description,
.authPhone__newView__main .authPhone__newView__actions__description-text {
  display: inline;
}
.authPhone__newView__actions__description span {
  display: inline;
}
.authPhone__newView__main .authPhone__newView-timer__newcode {
  margin: 2.7778rem 0 1.6667rem 0;
  text-align: center;
}

.authPhone__newView__main .authPhone__newView__actions__sms,
.authPhone__newView__main .authPhone__newView-timer {
  text-align: center;
}
.authPhone__newView__main .authPhone__newView__phone__input {
  margin: 0;
}

.authPhone__newView__main .authPhone__newView__actions__input {
  margin: 1.6667rem 0 0 0;
}
.authPhone__newView__modal .advantagesList__item {
  font-size: 0.9722rem;
}
.authPhone__newView__modal__section-list {
  padding: 0.9722rem 0 1.6667rem 0;
}

.authPhone__newView__modal__section-button {
  width: 16.6667rem;
  margin: 2.7778rem 0 0 0;
}

.bank__main__form {
  font-size: 1.25rem;
}
.bank__any__form {
  font-family: 'Stem', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--s18);
  line-height: 130%;
}

.authPhone__newView--instruction-order .authPhone__newView__phone__input,
.authPhone__newView--instruction-order .authPhone__newView__phone__button__default,
.authPhone__newView--instruction-order .authPhone__newView__actions__input {
  width: 100%;
}

/* isNewView */

.authPhone__newView__section-inputs {
  display: flex;
  flex-direction: column;
}

.authPhone__newView__section-inputs__firstline {
  display: flex;
}

.authPhone__newView__name__input__newView {
  padding: 0 1.25rem 0 0;
  width: 50%;
}

.authPhone__newView__section-inputs .input-big {
  font-size: 1.25rem;
  line-height: 1.613rem;
}
.authPhone__newView__section-inputs .input__section-required {
  color: #4d92dc;
  font-size: 1.25rem;
  line-height: 1.613rem;
  top: 1rem;
}

.authPhone__newView__phone__input__newView {
  width: 50%;
}
.bankruptcyp__formTitle {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6666rem;
  line-height: 130%;
}
.authPhone__newView__email__input__newView {
  padding: 1.25rem 0 0 0;
  width: 100%;
}
.authPhone__newView__section-terms {
  box-sizing: border-box;
  padding: 2.292rem 0 0 0;
  display: flex;
}
.bank__loader__section {
  height: 21.7361rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank__loader {
  width: 1.1111rem;
  height: 1.1111rem;
  border-radius: 100%;
  border-top: 0.0694rem solid #5e9cdf;
  border-left: 0.0694rem solid #5e9cdf;
  animation-name: rotation;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.authPhone__newView__terms__checkbox {
  margin: -0.208rem 0.875rem 0 0;
  display: flex;
  background: #ffffff;
  outline: 0.0625rem solid #4d92dc;
  outline-offset: -1px;
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
}

.authPhone__newView__terms__checkbox-error {
  margin: -0.208rem 0.875rem 0 0;
  display: flex;
  background: #ffffff;
  outline: 0.0625rem solid #fa5252;
  outline-offset: -1px;
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
}
.bankruptcy__select__options-active {
  height: 100%;
  max-height: 15rem;
}

.authPhone__newView__terms__description {
  font-family: 'Stem', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.972rem;
  line-height: 1.25rem;
  color: #adb5bd;
}

.authPhone__newView__terms__description__link {
  color: #4d92dc;
}

.authPhone__newView__terms__description__link:hover {
  opacity: 0.6;
  transition: 0.5s;
}

.bankruptcy-form {
  background: rgb(255, 255, 255);
  border-radius: 1.3889rem;
  padding: 2.7778rem;
  max-height: 27.2222rem;
  max-width: 33.3333rem;
  margin-left: auto;
}
.bankruptcyp-form-title {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6666rem;
  line-height: 130%;
}
.bankruptcyp-form__dates {
  display: flex;
  margin-top: 2.0833rem;
  margin-bottom: 1.3889rem;
}
.bankruptcy_date_call__default {
  width: 100%;
}
.bankruptcy_time_call__default {
  width: 100%;
}
.bankruptcy_date_call__modalDocument {
  width: 47%;
  margin-right: 1.3889rem;
}
.bankruptcy_time_call__modalDocument {
  width: 47%;
}
.fieldName {
  margin-bottom: var(--s20);
  @media screen and (max-width: $mobile-breakpoint) {
    margin-bottom: var(--s10);
  }
}
.formCallsSection {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-gap: rem(14px);
  grid-template-columns: min-content auto;
  margin-top: var(--s40);
  @media screen and (max-width: $mobile-breakpoint) {
    margin-bottom: var(--s20);
    margin-top: var(--s20);
  }
}
.formCallsToggle {
  display: flex;
}
.formCallsText {
  font-size: rem(18px);
  line-height: 130%;
  text-align: center;
  font-family: 'Stem', sans-serif;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: rem(14px);
    text-align: left;
  }
  
}
.bankruptcy_form_whatsup__default {
  align-items: center;
  max-height: 1.5972rem;
  display: flex;
  margin: var(--s40) 0;
}
.bankruptcy_form_whatsup__modalDocument {
  align-items: center;
  max-height: 1.5972rem;
  display: flex;
  margin-top: var(--s13);
}
.bankruptcy_form_whatsup_text__default {
  font-family: Stem, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  margin-left: 0.9722rem;
}
.bankruptcy_form_whatsup_text__modalDocument {
  font-family: Stem, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--s14);
  line-height: 130%;
  margin-left: 0.9722rem;
}
.bankruptcy_form__default {
  background: #fff;
  border-radius: 1.3889rem;
  padding: 2.7778rem;
  width: 100%;
  height: 100%;
  margin-left: auto;
  box-sizing: border-box;
  position: relative;
}
.bankruptcy_form__modalDocument {
  background: rgba(77, 146, 220, 0.1);
  border-radius: 1.3889rem;
  padding: var(--s30);
  width: 100%;
  max-width: 330px;
  margin-left: auto;
}
.documentModalForm__sectionTitle {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: var(--s30);
  line-height: rem(39px);
  margin-bottom: var(--s40);
  @media screen and (max-width: 450px) {
    font-size: var(--s20);
    margin-bottom: var(--s20);
    line-height: 130%;
  }
}
.bank__loader__section {
  height: 21.7361rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bank__loader {
  width: 1.1111rem;
  height: 1.1111rem;
  border-radius: 100%;
  border-top: 0.0694rem solid #5e9cdf;
  border-left: 0.0694rem solid #5e9cdf;
  animation-name: rotation;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.auth_phone {
  width: 100%;
}

.auth_phone__actions__input {
  margin: 1.6667rem 0 0;
}
.auth_phone__phone__button {
  margin-top: var(--s40);
}
.auth_phone__actions__description {
  display: flex;
  text-align: start;
}
.auth_phone__section {
}
.auth_phone__actions__description_number {
  font-family: 'Stem', sans-serif;
  font-weight: 500;
  margin: 0 0.4167rem 0 0;
}

.auth_phone__phone__input,
.auth_phone__actions__input,
.auth_phone__phone__button {
}

.auth_phone__modal__section_list {
  padding: 0.9722rem 0 1.6667rem;
}
.auth_phone__modal__text {
  margin-bottom: var(--s40);
}
.auth_phone__modal__section_button {
  max-width: 16.6666rem;
}
.auth_phone__actions__description_button,
.auth_phone__actions__sms {
  color: #4d92dc;
  cursor: pointer;
  font-family: 'Stem', sans-serif;
}

/* template main */

.auth_phone--main .auth_phone__phone__input,
.auth_phone--main .auth_phone__phone__button,
.auth_phone--main .auth_phone__actions__input {
  width: 100%;
}

.auth_phone--main .auth_phone__phone__button {
  margin: 2.7778rem 0 0 0;
}
.auth_phone--main .auth_phone__actions__description,
.auth_phone--main .auth_phone__actions__description_text {
  font-family: 'Stem', sans-serif;
  display: inline;
}
.auth_phone__actions__description span {
  font-family: 'Stem', sans-serif;
  display: inline;
}
.auth_phone--main .auth_phone-timer__newcode {
  margin: 2.7778rem 0 1.6667rem 0;
  text-align: center;
}

.auth_phone--main .auth_phone__actions__sms,
.auth_phone--main .auth_phone-timer {
  text-align: center;
  font-family: 'Stem', sans-serif;
}
.auth_phone--main .auth_phone__phone__input {
  margin: 0;
}

.auth_phone--main .auth_phone__actions__input {
  margin: 1.6667rem 0 0 0;
}
.auth_phone__modal .advantagesList__item {
  font-size: 0.9722rem;
}
.auth_phone__modal__section-list {
  padding: 0.9722rem 0 1.6667rem 0;
}

.auth_phone__modal__section-button {
  width: 16.6667rem;
  margin: 2.7778rem 0 0 0;
}

.auth_phone--instruction-order .auth_phone__phone__input,
.auth_phone--instruction-order .auth_phone__phone__button,
.auth_phone--instruction-order .auth_phone__actions__input {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .bankruptcy_form__default {
    min-height: rem(561px);
    height: unset;
  }
  .authPhone__newView__section-inputs .input-big {
    font-size: 0.972rem;
    line-height: 1.25rem;
  }
  .authPhone__newView__phone__button__default .button__section-text {
    font-size: 0.972rem;
    line-height: 1.25rem;
  }
  .bankruptcy_form__modalDocument {
    max-width: fit-content;
    margin-top: var(--s40);
  }
}

@media screen and (max-width: 450px) {
  .form__terms__default {
    margin-top: rem(20px);
    font-size: rem(12px);
  }

  .authPhone__newView__main .authPhone__newView__phone__button__default {
    margin: rem(20px) 0 0 0;
  }
  .auth_phone__phone__input,
  .auth_phone__phone__button,
  .auth_phone__actions__input {
    width: 100%;
  }
  .auth_phone__modal__check_phone {
    padding-bottom: 16px;
  }
  .auth_phone__modal__section_list {
    padding: 0 0 1.6667rem;
  }
  .bank__any__form {
    font-size: var(--s14);
  }
  .bankruptcy_form__default {
    padding: var(--s20);
    min-height: rem(457px);
    height: unset;
  }
  .toggleSection {
    width: 3.125rem;
    padding-top: 3px;
  }
  .bankruptcy_form_whatsup_text__default {
    font-size: 0.9722rem;
  }
  .authPhone__newView__phone__input,
  .authPhone__newView__phone__button__default,
  .authPhone__newView__actions__input {
    width: 100%;
  }
  .authPhone__newView__section-inputs__firstline {
    flex-direction: column;
  }
  .authPhone__newView__name__input__newView {
    padding: 0 0 1.25rem 0;
    width: 100%;
    font-size: 2rem;
  }
  .bankruptcyp__formTitle {
    font-size: 1.3889rem;
  }
  .authPhone__newView__phone__input__newView {
    width: 100%;
    margin-top: 0px;
  }
  .bankruptcy_time_call {
    margin-top: 1.3889rem;
    width: 100%;
    margin-bottom: rem(10px);
  }
  .bankruptcy_date_call {
    width: 100%;
  }
  .bankruptcy_time_call__modalDocument {
    margin-top: 1.3889rem;
    width: 100%;
  }
  .bankruptcy_date_call__modalDocument {
    width: 100%;
  }
  .bankruptcy_form__default {
    min-height: unset;
  }
}

.capWrapper {
  position: absolute;
  top: rem(-39px);
  right: rem(40px);
  width: rem(100px);
  height: rem(100px);
  @media screen and (max-width: 450px) {
    width: rem(60px);
    height: rem(60px);
    right: rem(20px);
    top: rem(-12px);
  }
}

.createOrderModal {
  max-width: rem(360px);
  @media screen and (max-width: 1024px) {
    max-width: rem(400px);
  }
  @media screen and (max-width: 450px) {
    max-width: rem(315px);
  }
  .iconContainer {
    width: rem(60px);
    height: rem(60px);
  }
  .titleClass {
    margin-top: rem(24px);
    font-weight: 500;
    font-size: rem(30px);
    @media screen and (max-width: 450px) {
      font-size: rem(24px);
    }
  }
  .description {
    margin-top: rem(24px);
    bold {
      font-style: normal;
      white-space: nowrap;
    }
  }
  .buttonContainer {
    margin-top: rem(40px);
  }
}

.authPhone {
  width: 100%;
}

.authPhone__phone__input,
.authPhone__phone__button,
.authPhone__actions__input {
  width: 27.7778rem;
}

.authPhone__actions__description {
  display: flex;
}

.authPhone__actions__descriptionNumber {
  font-weight: 500;
  margin: 0 0.4167rem 0 0.4167rem;
}

.authPhone__modal__title {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 2.0833rem;
  line-height: 2.8472rem;
  margin: 0 0 1.6667rem 0;
}

.authPhone__phone__input,
.authPhone__actions__input,
.authPhone__phone__button {
  margin: 1.3889rem 0 0 0;
}

.authPhone__actions__descriptionButton,
.authPhone__actions__sms {
  color: #4d92dc;
  cursor: pointer;
}

/* template main */

.authPhone--main .authPhone__phone__input,
.authPhone--main .authPhone__phone__button,
.authPhone--main .authPhone__actions__input {
  width: 100%;
}

.authPhone--main .authPhone__phone__button {
  margin: 2.7778rem 0 0 0;
}
.authPhone--main .authPhone__actions__description,
.authPhone--main .authPhone__actions__descriptionText {
  display: inline;
}
.authPhone__actions__description span {
  display: inline;
}
.authPhone--main .authPhone-timer__newcode {
  margin: 2.7778rem 0 1.6667rem 0;
  text-align: center;
}

.authPhone--main .authPhone__actions__sms,
.authPhone--main .authPhone-timer {
  text-align: center;
}
.authPhone--main .authPhone__phone__input {
  margin: 0;
}

.authPhone--main .authPhone__actions__input {
  margin: 1.6667rem 0 0 0;
}
.authPhone__modal .advantagesList__item {
  font-size: 0.9722rem;
}
.authPhone__modal__sectionList {
  padding: 1.667rem 0;
}

.authPhone__modal__sectionButton {
  width: 16.6667rem;
  margin: 2.7778rem 0 0 0;
}

@media screen and (max-width: 450px) {
  .authPhone__phone__input,
  .authPhone__phone__button,
  .authPhone__actions__input {
    width: 100%;
  }

  .authPhone__modal__title {
    font-size: 1.667rem;
  }

  .authPhone__modal__sectionButton {
    width: 100%;
  }
}

.sideServicesBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: rem(40px);
  min-width: rem(200px);
  margin: auto 0;
}

.serviceSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: rem(10px);
}

.serviceIconSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(60px);
  height: rem(60px);
  background: rgba(77, 146, 220, 0.1);
  border-radius: 100px;
}

.serviceIcon {
  width: rem(30px);
  height: rem(30px);
}

.serviceTitle {
  width: 100%;
  font-family: 'Stem', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(12px);
  line-height: 130%;
  text-align: center;
  color: #1E1E1E;
}

@media screen and (max-width: 450px) {
  .sideServicesBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: rem(10px);
    width: 100%;
    margin: 0 auto;
  }
}