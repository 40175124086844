.component {
  display: inline-block;
  border: .0625rem solid #d8dcdf;
  border-radius: 1.09375rem;
  -moz-border-radius: 1.09375rem;
  -webkit-border-radius: 1.09375rem;
  height: 1.25rem;
  width: 3.5rem;
  cursor: pointer;
  position: relative;
  transition: .3s ease;
}
.component::after {
  content: '';
  display: block;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background-color: #ffffff;
  border:.0625rem solid #d8dcdf;
  width: 1.75rem;
  height: 1.75rem;
  transition: .3s ease;
  position: absolute;
  top:-.3rem;
}
.active { background-color: var(--mainColor); }

.active::after { left: 1.75rem; }
.inactive::after { left: -.05rem; }
.hidden { display: none; }
