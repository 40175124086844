.component {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  z-index: 10;
  text-align: center;
}

.wrapper {
  overflow: auto;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(63, 63, 70, 0.9);
  transition: opacity 0.5s;
}

.active {
  display: block;
  z-index: 110;
  opacity: 1;
  transition: opacity 0.5s;
}
.inactive {
  position: absolute;
  height: 0;
  width: 0;
  z-index: -1;
  opacity: 0;
}

.section {
}
.sectionOverlay {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;
}
.sectionBody {
  position: relative;

  margin: 5rem 0;
  display: inline-block;

  z-index: 2;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.default {
  width: 48.93rem;
  border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  padding: 4.375rem;
  background-color: #ffffff;
}

.default-wide {
  max-width: 56.25rem;
  border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  padding: 4.375rem;
  background-color: #ffffff;
}

.laywerhelp {
  width: 40.3472rem;
  border-radius: 0.4167rem;
  -moz-border-radius: 0.4167rem;
  -webkit-border-radius: 0.4167rem;
  padding: 4.8611rem;
  background-color: #ffffff;
  height: 24.5833rem;
}

.small {
  width: 28.4027rem;

  border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;

  padding: 2.15rem;

  background-color: #ffffff;
}
.medium {
  min-width: 33.3333rem;

  border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;

  padding: 4.8611rem;

  background-color: #ffffff;
}

.sms-not-get {
  border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  padding: 4.8611rem;
  background-color: #ffffff;
}

.case-modal {
  max-width: 62.5rem;
  min-width: 62.5rem;
  border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  padding: 4.861rem;
  background-color: #ffffff;
}

.body {
  text-align: left;
}
.bodySectionClose {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;

  text-align: right;
}
.bodySectionTitle {
  padding: 0 0 0.6875rem 0;
}
.bodySectionDescription {
  padding: 0 0 1.875rem 0;
}

.close {
  position: relative;

  width: 1.9375rem;
  height: 1.9375rem;

  border: 0.0625rem solid #5e9cdf;

  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  padding: 0.59rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.closeSrc {
  display: block;

  width: 100%;
}

.auth {
  padding: 2.5rem;
  width: unset;
}

.auth .bodySectionClose,
.auth .bodySectionDescription {
  display: none;
}

.auth .bodySectionTitle {
  text-align: center;
  padding: 0 0 1rem 0;
  max-width: 20rem;
}

.pay-status {
  padding: 3.125rem;
  width: 35rem;
  background-color: #ffffff;
  border-radius: 0.4166rem;
}

.pay-status .bodySectionTitle {
  text-align: center;
}
.pay-status .bodySectionContent {
  display: flex;
  justify-content: center;
}

.calc {
  width: 45.8333rem;
}
.calc .bodySectionClose {
  top: 1rem;
  right: 1rem;
}
.calc .close {
  border: unset;
}

.calc .iconZone {
  stroke: #adb5bd;
}

.subscription {
  background-color: #ffffff;
  border-radius: 0.4166rem;
  max-width: 54.375rem;
}

.banner {
  background-color: #ffffff;
  border-radius: 0.4167rem;
  width: 43.75rem;
  padding: 4.8611rem;
}

.banner .bodySectionDescription {
  text-align: center;
  margin: 0 auto;
  width: 30.5556rem;
}

.banner .bodySectionTitle {
  text-align: center;
  margin: 0 auto;
}

.fixed {
  width: 56.9444rem;
  background-color: #ffffff;
  border-radius: 0.4167rem;
  padding: 4.8611rem 4.8611rem 0;
  box-sizing: border-box;
  position: relative;
}

.fixed::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 5.5556rem;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 90.85%);
}

.fixed .bodySectionContent {
  max-height: 67vh;
  overflow: scroll;
  padding: 0 0 4.8611rem 0;
}

.fixed .bodySectionTitle .title {
  text-transform: unset;
  font-size: 2.0833rem;
  font-weight: 500;
  padding: 0 0 2.0833rem 0;
}

.video {
  width: 61.1111rem;
  background-color: #ffffff;
  border-radius: 0.4167rem;
  padding: 4.8611rem;
}

@media screen and (max-width: 1024px) {
  .video {
    width: 100%;
  }

  .sms-not-get {
    padding: 3.472rem;
  }

  .case-modal {
    padding: 3.472rem;
    max-width: 48.611rem;
    min-width: 48.611rem;
  }
}

@media screen and (max-width: 450.9px) {
  .sectionBody {
    width: 95%;
    padding: 1.3784rem 0.875rem;
    top: 1rem;
  }

  .medium {
    min-width: unset;
  }

  .sms-not-get {
    padding: 2.083rem 1.389rem;
  }

  .case-modal {
    max-width: unset;
    min-width: unset;
    padding: 2.083rem 1.389rem;
  }

  .bodySectionClose {
    top: -3rem;
    right: 0.5rem;
  }

  .close {
    border: 0.0625rem solid #ffffff;
  }

  .closeSrc .iconZone {
    stroke: #ffffff;
  }
}
