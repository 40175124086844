.component {
  font-family: "Manrope", sans-serif;
  font-weight: bold;
  color: inherit;
}

h1.component {
  font-size: var(--s50);
  line-height: var(--s65);
}

h2.component {
  font-size: var(--s40);
  line-height: var(--s52);
}

@media screen and (max-width: 1024px) {
  h1.component {
    font-size: var(--s40);
    line-height: var(--s52);
  }
}

@media screen and (max-width: 450px) {
  h1.component {
    font-size: var(--s30);
    line-height: var(--s39);
  }
  h2.component {
    font-size: var(--s32);
    line-height: var(--s42);
  }
}
