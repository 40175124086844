.component {
  line-height: normal;
  position: relative;
  height: fit-content;
  display: inline-flex;
  align-items: center;
}
.sectionContent {
  position: absolute;
  display: none;
  bottom: 1.5rem;
  width: 18.681rem;
  background-color: #ffffff;
  border: 0.0625rem #e2e5e7 solid;
  padding: 1.389rem;
  border-radius: 0.208rem;
  left: 10.694rem;
  z-index: 5;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.03);
}
.content {
  font-size: var(--s14);
  line-height: var(--s18);
  text-align: start;
}
.component:hover .sectionContent {
  display: block;
}
.icon {
  min-width: 1.75rem;
  min-height: 1.75rem;
  border: 0.0625rem solid #e2e5e7;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  display: block;
}
.icon--control {
  border: 0;
  width: 1.125rem;
  height: 1.125rem;
}
.icon::before {
  content: "?";
  background-color: #e2e5e7;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 1.125rem;
  height: 1.125rem;
  display: block;
  font-size: 0.625rem;
  text-align: center;
  line-height: 1.15rem;
  margin: 0.3125rem;
  cursor: pointer;
}
.icon--control::before {
  margin: 0;
}
.left {
  margin-left: -10rem;
}
.top {
  bottom: 1.0562rem;
  top: unset;
}
.sectionTitle {
  padding: 0 0 0 0.6944rem;
}
.title {
  font-size: 0.9722rem;
  opacity: 0.6;
}

@media screen and (max-width: 450px) {
  .component {
    position: static;
  }
  .sectionContent {
    padding: 1rem;
    border-radius: unset;
    width: 100%;
    top: unset;
    right: 0;
    transform: translate(0, 63%);
  }
}
