@import "./exportVariables.scss";

.blockSpacing {
  margin: rem(140px) auto 0 auto;
  max-width: $max-content-width;
  @media screen and (max-width: $tablet-breakpoint) {
    margin-top: rem(100px);
  }
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: rem(80px);
  }

  > * {
    margin: 0 auto;
  }

  &.paddingTopHeader {
    margin-top: 0;
    padding-top: rem(125px);
    @media screen and (max-width: $tablet-breakpoint) {
      padding-top: rem(114px);
    }
    @media screen and (max-width: $mobile-breakpoint) {
      padding-top: rem(84px);
    }
  }

  &.noTopMargin {
    margin-top: 0;
  }

  &.blockOverHeader {
    margin-top: rem(-100px);
  }

  &.maxScreenWidth {
    max-width: $max-width;
  }

  &.smallerWidth {
    max-width: 1240px;
  }

  &.partnersWidth {
    max-width: 1170px;
  }

  &.smallestWidth {
    max-width: 840px;
  }

  &.bottomMargin {
    margin-bottom: rem(140px);
  }

  &.bottomPadding {
    padding-bottom: rem(140px);
  }

  &.padding {
    box-sizing: border-box;
    padding: rem(80px);
    border-radius: 40px;
    @media screen and (max-width: $tablet-breakpoint) {
      padding: rem(60px) rem(34px);
    }
    @media screen and (max-width: $mobile-breakpoint) {
      padding: rem(40px) rem(16px);
    }
    &.whitePadding {
      background: white;
    }
    &.bluePadding {
      background: rgba(77, 146, 220, 0.1);
      padding-top: rem(100px);
      padding-bottom: rem(100px);
      @media screen and (max-width: $tablet-breakpoint) {
        padding: rem(80px) rem(30px);
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: rem(40px) rem(16px);
      }
    }
  }

  &.blockShadow {
    box-shadow: 0 rem(5px) rem(25px) rgb(44 54 66 / 5%);
  }
}

.smallBlockMargin {
  margin: 0 rem(45px);
  @media screen and (max-device-width: $tablet-breakpoint) {
    margin: 0 rem(20px);
  }
  @media screen and (max-device-width: $mobile-breakpoint) {
    margin: 0;
  }
  &.hiddenOverflow {
    position: relative;
    overflow: hidden;
    margin-right: 0;
  }

  .blockTitle {
    margin: 0 auto rem(60px) auto;
    text-align: center;
    max-width: rem(600px);
    @media screen and (max-device-width: $tablet-breakpoint) {
      margin-bottom: rem(40px);
      max-width: rem(500px);
    }
  }
}

.defaultBlockMargin {
  margin: 0 rem(100px);
  @media screen and (max-device-width: $tablet-breakpoint) {
    margin: 0 rem(34px);
  }
  @media screen and (max-device-width: $mobile-breakpoint) {
    margin: 0 rem(16px);
  }
  &.lessMargin {
    @media screen and (min-device-width: $mobile-breakpoint) {
      margin: 0 rem(16px);
    }
  }
  &.mobileNoMargin {
    margin: 0;
    @media screen and (min-device-width: $tablet-breakpoint) {
      margin: 0 rem(45px);
    }
  }
  &.hideOverflow {
    overflow: hidden;
  }
  &.topBorder {
    border-top: 1px solid rgba(229, 229, 229, 1);
  }
}
